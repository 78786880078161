import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './modules/login.module#LoginModule' },
  { path: 'dashboard', loadChildren: './modules/dashboard.module#DashBoardModule', canActivate: [AuthGuard] },
  { path: 'role', loadChildren: './modules/role-manager.module#RoleMangerModule', canActivate: [AuthGuard] },
  { path: 'prediction', loadChildren: './modules/prediction-manager.module#PredictionModule', canActivate: [AuthGuard] },
  {
    path: 'match-manager',
    loadChildren: './components/match-manager/match-manager.module#MatchManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'sports-manager',
    loadChildren: './components/sports-manager/sports-manager.module#SportsManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'default-screen-manager',
    loadChildren: './components/default-screen-manager/default-screen-manager.module#DefaultScreenManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'update-manager',
    loadChildren: './components/update-manager/update-manager.module#UpdateManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'home-screen-manager',
    loadChildren: './components/home-screen-manager/home-screen-manager.module#HomeScreenManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'notification-manager',
    loadChildren: './components/notification-manager/notification-manager.module#NotificationManagerModule',
    canActivate: [AuthGuard]
  },
  { path: 'video', loadChildren: './modules/video-manger.module#VideoMangerModule', canActivate: [AuthGuard] },
  { path: 'live-stream', loadChildren: './modules/live-manager.module#LiveManagerModule', canActivate: [AuthGuard] },
  { path: 'news', loadChildren: './modules/news-manager.module#NewsMangerModule', canActivate: [AuthGuard] },
  {
    path: 'player-manager',
    loadChildren: './components/player-manager/player-manager.module#PlayerManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'team-manager',
    loadChildren: './components/team-manager/team-manager.module#TeamManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'competition-manager',
    loadChildren: './components/competition-manager/competition-manager.module#CompetitionManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'ads-manager',
    loadChildren: './components/ads-manager/ads-manager.module#AdsManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'website-manager',
    loadChildren: './components/website-manager/website-manager.module#WebsiteManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'search-manager',
    loadChildren: './components/search-manager/search-manager.module#SearchManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'stories-manager',
    loadChildren: './components/stories-manager/stories-manager.module#StoriesManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'error-logs',
    loadChildren: './components/error-logs/error-logs.module#ErrorLogsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'posts',
    loadChildren: './components/posts/posts.module#PostsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'gallery',
    loadChildren: './components/gallery/gallery.module#GalleryModule',
    canActivate: [AuthGuard]
  },
  /*{
    path: 'posts-manager',
    loadChildren: './components/posts-manager/posts-manager.module#PostsManagerModule',
    canActivate: [AuthGuard]
  },*/
  {
    path: 'home-page-manager',
    loadChildren: './components/home-page-manager/home-page-manager.module#HomePageManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'checkout-manager',
    loadChildren: './components/checkout-manager/checkout-manager.module#CheckoutManagerModule',
    canActivate: [AuthGuard]
  }, 
  {
    path: 'news-detail',
    loadChildren: () => import('./components/news/news.module').then(m => m.NewsModule),
    canActivate: [AuthGuard]
  },

  // {
  //   path: 'media',
  //   loadChildren: './shared/componets/media/media.module#MediaModule',
  //   canActivate: [AuthGuard]
  // },

  {
    path: 'media',
    loadChildren: './components/media/media.module#MediaModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'activity-log',
    loadChildren: './components/activity-log/activity-log.module#ActivityLogModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'contact-us',
    loadChildren: './components/contact-us/contact-us.module#ContactUsModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'change-password',
    loadChildren: './components/change-password/change-password.module#ChangePasswordModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'subscribe',
    loadChildren: './components/subscribe/subscribe.module#SubscribeModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration-manager',
    loadChildren: './components/configuration/configuration.module#ConfigurationModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'betting-page-manager',
    loadChildren: './betting-article-page-manager/betting-article-page-manager.module#BettingArticlePageManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'sub-header-menu-manager',
    loadChildren: './betting-article-page-manager/betting-article-page-manager.module#BettingArticlePageManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'table-manager',
    loadChildren: './table-manager/table-manager.module#TableManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'reels-manager',
    loadChildren : './components/reel-manager/reels-manager.module#ReelsManagerModule',
    canActivate: [AuthGuard]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
