declare var CKEDITOR;

export class AppConstant {
  public static get PAGE_LIMIT(): Number {
    return 10;
  }

  public static get CONFERMATION_NO(): string {
    return "No";
  }

  public static get CONFERMATION_YES(): string {
    return "Yes";
  }

  public static get USER_TOKEN_KEY(): string {
    return "token";
  }

  public static get FAILED_MESSAGE(): string {
    return "Failed";
  }

  public static get SUCCESS_MEASSAGE(): string {
    return "Success";
  }

  public static get GREATER_THAN_ZERO(): string {
    return "position should be grater than 0";
  }

  public static get DEVICE_TOKEN(): string {
    return "deviceToken";
  }

  public static get LOGIN_DATA_KEY(): string {
    return "userId";
  }

  public static get USER_INFO(): string {
    return "userInfo";
  }

  public static get USER_TYPE_KEY(): string {
    return "userType";
  }

  public static get MARK_DATA_KEY(): string {
    return "mark as trending";
  }

  public static get UNMARK_DATA_KEY(): string {
    return "un-mark as trending";
  }

  public static get BLANK_STRING(): string {
    return "";
  }

  public static get CKEDITOR_CONFIG() {
    return {
      versionCheck: false,
      forcePasteAsPlainText: false,
      height: 500,
      removePlugins: "iframe",
      toolbar: [
        {
          name: "basicstyles",
          items: [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "Subscript",
            "Superscript",
            "BlockQuote",
          ],
        },
        {
          name: "paragraph",
          items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "-",
            "Blockquote",
          ],
        },
        {
          name: "insert",
          items: ["Iframe", "Image", "Table", "Smiley", "SpecialChar"],
        },
        { name: "document", items: ["Find", "Replace"] },
        { name: "clipboard", items: ["Cut", "Copy", "Paste", "Undo", "Redo"] },
        { name: "link", items: ["Link", "Unlink"] },
        { name: "styles", items: ["Format"] },
        {
          name: "align",
          items: [
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
          ],
        },
        { name: "tools", items: ["Maximize"] },
        { name: "document", items: ["Source"] },
      ],
      format_tags: "p;h1;h2;h3;h4;h5;h6",
      allowedContent: true,
      spellcheck: true,
      contentsCss:
        "data:text/css;base64," +
        btoa(`
          body {
            font-family: 'Noto Sans Display', sans-serif;
            font-size: 18px;
          }
          img {
            display: block;
            width: 100%;
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #5c5c5c !important;
            padding: 8px;
            text-align: left;
          }
          th p, td p {
            margin: 0px;
          }
        `),
      on: {
        paste: function (evt) {
          const data = evt.data;
          data.dataValue = data.dataValue.replace(/ style="[^"]*"/g, "");
          data.dataValue = data.dataValue.replace(/ dir="[^"]*"/g, "");
          data.dataValue = data.dataValue.replace(/ id="[^"]*"/g, "");
          data.dataValue = data.dataValue.replace(/ scope="[^"]*"/g, "");
          data.dataValue = data.dataValue.replace(/ width="[^"]*"/g, "");
          data.dataValue = data.dataValue.replace(/<\/?b>/g, "");
          data.dataValue = data.dataValue.replace(/&nbsp;/g, ' ');
          data.dataValue = data.dataValue.replace(
            /<colgroup[^>]*>[\s\S]*?<\/colgroup>/g,
            ""
          );
          data.dataValue = data.dataValue.replace(
            /<table/g,
            '<section class="article-table-reponsive"><table'
          );
          data.dataValue = data.dataValue.replace(
            /<\/table>/g,
            "</table></section>"
          );
        },
        instanceReady: function (evt: any) {
          evt.editor.dataProcessor.htmlFilter.addRules({
            elements: {
                table: function(element) {
                    delete element.attributes.width;
                    delete element.attributes.cellspacing;
                    delete element.attributes.style;
                    delete element.attributes.cellpadding;
                    delete element.attributes.border;
                }
            }
        });
          const editor = evt.editor;
          // Detect when Link button is clicked
          editor.on("dialogShow", function (event: any) {
            if (event.data.getName() === "link") {
              const elementId = document.getElementById("addEditForm");
              if(elementId) {
                document.getElementById("addEditForm").classList.add("modal-overflow-change");
              }              
            }
          });

          editor.on("dialogHide", function (event: any) {
            if (event.data.getName() === "link") {
              const elementId = document.getElementById("addEditForm");
              if(elementId) {
                document.getElementById("addEditForm").classList.remove("modal-overflow-change");
              }
            }
          });
        },
      },
      extraPlugins: "wsc,scayt", 
      scayt_autoStartup: true, 
    };
  }
  
  /*  creating the Function For the Log */

  public static print(value) {
    let val = console.log(value);
    return val;
  }
}
